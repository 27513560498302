import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import StoreContext from 'src/context/StoreContext'
import LoadingEllipsis from 'src/components/LoadingEllipsis'
import { HiddenOnMobile } from 'src/utils/styles/global-styles'
import { FormattedCurrency } from 'src/utils/FormattedCurrency'
import {
  CAL_BUY_LINK,
  CAL_MAX_BUY_LINK,
  CAL_MAX_BLACK_BUY_LINK,
  CAL_MAX_WHITE_BUY_LINK,
  CAL_MAX_ALUMINUM_BUY_LINK,
  CAL_MAX_CHARCOAL_BUY_LINK,
  IS_FOREIGN_COUNTRY,
} from '../../../utils/constants'
import {
  TableRow,
  DesktopOnlyTD,
  TD,
  TitleLink,
  Remove,
  ProductTD,
  ProductInfo,
  LineItemTotalCtn,
  HiddenOnDesktop,
  LeftQuantityButton,
  RightQuantityButton,
  Price,
  FrameWithPlusExplainer,
  SubTotal,
} from './styles'

const LineItem = ({
  item,
  updatingPrices,
  setUpdatingPrices,
  checkForAvailableDiscount,
  strikethrough,
  subtotalQuantity,
}) => {
  const [shown, setShown] = useState(true)
  const {
    store: { cart, client },
    updateLineItem,
    removeLineItem,
  } = useContext(StoreContext)
  const {
    id,
    quantity,
    merchandise: {
      image: { url },
      priceV2: { amount },
      product: { title, handle },
    },
  } = item?.node ?? {}

  const updatePricesWrapper = async (cb) => {
    setUpdatingPrices(true)
    await cb()
    setUpdatingPrices(false)
  }

  const handleRemoveLineItem = async () => {
    updatePricesWrapper(async () => {
      setShown(false)
      const updatedCheckout = await removeLineItem(id)
      // checkForAvailableDiscount(updatedCheckout)
    })
  }

  const decrementLineItem = async () => {
    updatePricesWrapper(async () => {
      const newQuantity = quantity - 1
      const updatedCheckout = await updateLineItem(id, newQuantity)
      // checkForAvailableDiscount(updatedCheckout)
    })
  }

  const incrementLineItem = async () => {
    updatePricesWrapper(async () => {
      const newQuantity = quantity + 1
      const updatedCheckout = await updateLineItem(id, newQuantity)
      // checkForAvailableDiscount(updatedCheckout)
    })
  }

  if (!shown) return null

  const calendarPlusCopy =
    "Add photos and use Magic Import on any of your Skylight Calendars with the Plus Plan. Your first month of Plus is free! After that, you'll be billed $39 annually. Cancel anytime."

  const plusFreeTrialCopy = (price) =>
    `Add photos and use Magic Import on any of your Skylight Calendars with the Plus Plan. Your first 2 weeks of Plus are free! After that, you'll be billed ${price} annually. Cancel anytime.`

  const productDisclosure = {
    'The Skylight Calendar with Plus Plan': calendarPlusCopy,
    'The Skylight Calendar 15" with Plus Plan': calendarPlusCopy,
    'Calendar Plus':
      'To activate your subscription, check out using the same email your Skylight Calendar is tied to. You can find your tied email in the profile tab of the Skylight app.',
    // Free Trial test variants
    'Skylight Plus ($39.99/yr) w/ Free Trial': plusFreeTrialCopy('$39.99'),
    'Skylight Plus ($49.99/yr) w/ Free Trial': plusFreeTrialCopy('$49.99'),
    'Skylight Plus ($59.99/yr) w/ Free Trial': plusFreeTrialCopy('$59.99'),
    // -- End of Free Trial test variants
  }

  const PRODUCT_LINK_OVERRIDE = {
    'The Skylight Calendar with Plus Plan': CAL_BUY_LINK,
    'The Skylight Calendar 15" with Plus Plan': CAL_BUY_LINK,
    'Skylight Calendar Max': CAL_MAX_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Natural Aluminum)': CAL_MAX_ALUMINUM_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Charcoal)': CAL_MAX_CHARCOAL_BUY_LINK,
    'Skylight Calendar Max (Classic/White)': CAL_MAX_WHITE_BUY_LINK,
    'Skylight Calendar Max (Classic/Black)': CAL_MAX_BLACK_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Natural Aluminum) with Plus Plan': CAL_MAX_ALUMINUM_BUY_LINK,
    'Skylight Calendar Max (Shadow Box/Charcoal) with Plus Plan': CAL_MAX_CHARCOAL_BUY_LINK,
    'Skylight Calendar Max (Classic/White) with Plus Plan': CAL_MAX_WHITE_BUY_LINK,
    'Skylight Calendar Max (Classic/Black) with Plus Plan': CAL_MAX_BLACK_BUY_LINK,
  }

  const productLink = PRODUCT_LINK_OVERRIDE[title] || `/products/${handle}`
  return (
    <TableRow>
      <ProductTD textAlign="left">
        <HiddenOnMobile>
          <Link to={productLink}>
            <img src={url} width={100} alt={title} />
          </Link>
        </HiddenOnMobile>
        <ProductInfo>
          <TitleLink to={productLink}>{title}</TitleLink>
          <FrameWithPlusExplainer>
            {IS_FOREIGN_COUNTRY && title === 'The Skylight Frame' ? null : (
              <Trans>{productDisclosure[title]}</Trans>
            )}
          </FrameWithPlusExplainer>
          <Remove onClick={handleRemoveLineItem}>
            <Trans>Remove</Trans>
          </Remove>
        </ProductInfo>
      </ProductTD>
      <DesktopOnlyTD>
        <TD>
          <p>{FormattedCurrency(amount)}</p>
        </TD>
      </DesktopOnlyTD>
      <TD>
        <LeftQuantityButton type="button" onClick={decrementLineItem} disabled={updatingPrices}>
          -
        </LeftQuantityButton>
        {'  '}
        {quantity}
        {'  '}
        <RightQuantityButton
          type="button"
          onClick={incrementLineItem}
          disabled={updatingPrices || subtotalQuantity > 24}
        >
          +
        </RightQuantityButton>
      </TD>
      <HiddenOnDesktop>
        <TD center>
          <Price red={strikethrough}>{FormattedCurrency(amount)}</Price>
        </TD>
      </HiddenOnDesktop>
      <DesktopOnlyTD>
        <LineItemTotalCtn>
          {updatingPrices ? (
            <LoadingEllipsis />
          ) : (
            <SubTotal strikethrough={strikethrough}>
              {FormattedCurrency(quantity * amount)}
            </SubTotal>
          )}
        </LineItemTotalCtn>
      </DesktopOnlyTD>
    </TableRow>
  )
}

export default LineItem

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  setUpdatingPrices: PropTypes.func.isRequired,
  updatingPrices: PropTypes.bool.isRequired,
  checkForAvailableDiscount: PropTypes.func.isRequired,
  strikethrough: PropTypes.bool.isRequired,
  subtotalQuantity: PropTypes.number.isRequired,
}
